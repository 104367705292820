export default {
  definitions: {},
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "http://example.com/root.json",
  type: "object",
  title: "The Root Schema",
  required: ["form", "contacts", "searchResults", "searchTerms"],
  properties: {
    form: {
      $id: "#/properties/form",
      type: "object",
      title: "The Form Schema"
    },
    contacts: {
      $id: "#/properties/contacts",
      type: "object",
      title: "The Contacts Schema"
    },
    searchResults: {
      $id: "#/properties/searchResults",
      type: "array",
      title: "The Searchresults Schema",
      items: {
        $id: "#/properties/searchResults/items",
        type: "string",
        title: "The Items Schema",
        default: "",
        examples: ["lilly", "john", "smith", "jane", "doe"],
        pattern: "^(.*)$"
      }
    },
    searchTerms: {
      $id: "#/properties/searchTerms",
      type: "array",
      title: "The Searchterms Schema",
      items: {
        $id: "#/properties/searchTerms/items",
        type: "string",
        title: "The Items Schema",
        default: "",
        examples: ["Smith", "Alan", "Lilly", "Jane"],
        pattern: "^(.*)$"
      }
    }
  }
};
