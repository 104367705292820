import React from "react";
import { connect } from "react-redux";
import { fetchContact } from "../../actions/index.js";

class ContactView extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params;

    this.props.fetchContact(id);
  }

  render() {
    if (!this.props.contact) {
      return <div>Loading...</div>;
    }
    const { name, phone } = this.props.contact;
    return (
      <div>
        <h1>Details:</h1>
        <div>
          <h2>{name}</h2>
        </div>
        <div>{phone}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { contact: state.contacts[ownProps.match.params.id] };
};

export default connect(
  mapStateToProps,
  { fetchContact }
)(ContactView);
