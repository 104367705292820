import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { fetchContacts } from "../../actions/index.js";
import ContactList from "./ContactList.js";

class ContactsList extends React.Component {
  componentDidMount() {
    this.props.fetchContacts();
  }

  renderCreate() {
    return (
      <div style={{ textAlign: "right" }}>
        <Link to="/create" className="ui button primary">
          Create Contact
        </Link>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h1 className="ui large header">Contacts</h1>
        <div className="ui celled list">
          <ContactList contacts={this.props.contacts} />
        </div>
        {this.renderCreate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: Object.values(state.contacts)
  };
};

export default connect(
  mapStateToProps,
  { fetchContacts }
)(ContactsList);
