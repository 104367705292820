const validateName = (name) => {
  return /^[a-zA-Z\s]*$/.test(name);
};

const validatePhone = (phone) => {
  return (
    /^[0-9.()+]*$/i.test(phone) &&
    ((phone.startsWith("+") && !phone.startsWith("0", 1) && phone.length > 1) ||
      phone.startsWith("0")) &&
    !phone.includes("+", 1) &&
    (phone.match(/\(/g) !== null ? phone.match(/\(/g).length < 2 : true) &&
    (phone.match(/\)/g) !== null ? phone.match(/\)/g).length < 2 : true)
  );
};

export const validate = (formValues) => {
  const errors = {};
  const { name, phone } = formValues;
  if (!name) {
    errors.name = "You must enter name";
  } else if (!validateName(name)) {
    errors.name = "Name may only contain letters and blanks";
  }
  if (!phone) {
    errors.phone = "You must enter a phone number";
  } else if (!validatePhone(phone)) {
    errors.phone =
      "Phone number must start either with a + or a 0. If it starts with a +, it cannot be followed by a 0.";
  }
  return errors;
};

export const validateSet = (formValues) => {
  const errors = {};
  if (!formValues.contacts || !formValues.contacts.length) {
    errors.contacts = { _error: "At least one contact must be entered" };
  } else {
    const contactsArrayErrors = [];
    formValues.contacts.forEach((contact, contactIndex) => {
      const contactError = validate(contact);
      if (contactError !== {}) {
        contactsArrayErrors[contactIndex] = contactError;
      }
    });
    if (contactsArrayErrors.length) {
      errors.contacts = contactsArrayErrors;
    }
  }
  return errors;
};
