import "./SearchHistory.scss";
import React from "react";

const renderTerms = (searchTerms, onHistoryTermSubmit) => {
  const lastIndex = searchTerms.length;
  return searchTerms.map((term, index) => {
    return (
      <span
        className="clickable"
        key={index}
        onClick={() => onHistoryTermSubmit(term)}
      >
        {term}
        {lastIndex !== index + 1 ? ", " : ""}
      </span>
    );
  });
};

const SearchHistory = ({ searchTerms, onHistoryTermSubmit }) => {
  if (!searchTerms || searchTerms.length === 0) return null;
  return (
    <p className="search-history">
      Search History: {renderTerms(searchTerms, onHistoryTermSubmit)}
    </p>
  );
};

export default SearchHistory;
