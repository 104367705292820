import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { fetchContact, editContact } from "../../actions/index.js";
import ContactForm from "./ContactForm.js";

class ContactEdit extends React.Component {
  componentDidMount() {
    this.props.fetchContact(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    this.props.editContact(this.props.match.params.id, formValues);
  };

  render() {
    if (!this.props.contact) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <h3>Edit Contact</h3>
        <ContactForm
          initialValues={_.pick(this.props.contact, "name", "phone")}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { contact: state.contacts[ownProps.match.params.id] };
};

export default connect(
  mapStateToProps,
  { fetchContact, editContact }
)(ContactEdit);
