import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import contactReducer from "./contactReducer.js";
import contactSearchReducer from "./contactSearchReducer.js";
import searchTermsReducer from "./searchTermsReducer.js";

const version = () => "1";

export const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

export const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    const state = JSON.parse(serializedState);
    if (state.version === version()) {
      return state;
    }
  } catch (e) {
    console.log(e);
  }
  return undefined;
};

export default combineReducers({
  form: formReducer,
  contacts: contactReducer,
  searchResults: contactSearchReducer,
  searchTerms: searchTermsReducer,
  version
});
