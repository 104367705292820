//import _ from "lodash";
import { slugify } from "../common/index.js";
import history from "../history.js";
import {
  CREATE_CONTACT,
  CREATE_CONTACTS,
  SEARCH_CONTACTS,
  FETCH_CONTACTS,
  FETCH_CONTACT,
  DELETE_CONTACT,
  EDIT_CONTACT,
  SEARCH_TERM
} from "./types.js";

export const createContact = (formValues) => (dispatch) => {
  dispatch({
    type: CREATE_CONTACT,
    payload: { ...formValues, id: slugify(formValues.name) }
  });

  history.push("/");
};

export const createContacts = (formValues) => (dispatch) => {
  const payloadArray = formValues.contacts.map((contact) => {
    const id = slugify(contact.name);
    return { [id]: { ...contact, id: id } };
  });
  const payload = {};
  payloadArray.reduce((o, a) => {
    return Object.assign(o, a);
  }, payload);
  dispatch({
    type: CREATE_CONTACTS,
    payload: payload
  });

  history.push("/");
};

export const searchContacts = (term) => (dispatch, getState) => {
  const contacts = getState().contacts;
  let payload = [];
  if (term) {
    if (term.match(/\d+/g)) {
      payload = Object.values(contacts).filter(
        (contact) => contact.phone === term
      );
    } else {
      payload = Object.values(contacts).filter((contact) =>
        contact.name.toLowerCase().includes(term.toLowerCase())
      );
    }
    payload = payload.map((contact) => contact.id);
  } else {
    payload = Object.keys(contacts);
  }
  dispatch({
    type: SEARCH_CONTACTS,
    payload: payload
  });
  dispatch({
    type: SEARCH_TERM,
    payload: term
  });
};

export const fetchContacts = () => (dispatch, getState) => {
  dispatch({ type: FETCH_CONTACTS, payload: getState().contacts });
};

export const fetchContact = (id) => (dispatch) => {
  dispatch({ type: FETCH_CONTACT, payload: id });
};

export const editContact = (id, formValues) => (dispatch) => {
  dispatch({ type: EDIT_CONTACT, payload: { ...formValues, id } });
  history.push("/");
};

export const deleteContact = (id) => (dispatch) => {
  dispatch({ type: DELETE_CONTACT, payload: id });
  history.push("/");
};
