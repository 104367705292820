import "./ContactForm.scss";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate } from "./Validate.js";

class ContactForm extends React.Component {
  renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  };

  renderInput = ({ input, label, meta }) => {
    const className = `field${meta.error && meta.touched ? " error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <div className="two fields">
          <Field
            name="name"
            component={this.renderInput}
            label="Enter Name: "
          />
          <Field
            name="phone"
            component={this.renderInput}
            label="Enter Phone Number: "
          />
          <button className="ui button primary">Submit</button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: "contactForm",
  validate
})(ContactForm);
