import React from "react";
import { Link } from "react-router-dom";

const CreateLink = () => {
  return (
    <div style={{ textAlign: "right" }}>
      <Link to="/create" className="ui button primary">
        Create Contact
      </Link>
    </div>
  );
};

export default CreateLink;
