import React from "react";
import { Router, Route } from "react-router-dom";

import Header from "./Header.js";
import ContactCreate from "./contact/ContactCreate.js";
import ContactCreateBatch from "./contact/ContactBatch.js";
import ContactsList from "./contact/ContactsList.js";
import ContactSearch from "./contact/ContactSearch.js";
import ContactView from "./contact/ContactView.js";
import ContactEdit from "./contact/ContactEdit.js";
import ContactDelete from "./contact/ContactDelete.js";
import history from "../history.js";

const App = () => {
  return (
    <div className="ui page grid">
      <Router history={history}>
        <div className="fill-w">
          <Header />
          <div className="row">
            <div className="column padding-reset">
              <div className="ui message">
                <Route path="/" exact component={ContactsList} />
                <Route path="/create" exact component={ContactCreate} />
                <Route
                  path="/batchcreate"
                  exact
                  component={ContactCreateBatch}
                />
                <Route path="/search/:term?" component={ContactSearch} />
                <Route path="/view/:id" component={ContactView} />
                <Route path="/edit/:id" component={ContactEdit} />
                <Route path="/delete/:id" exact component={ContactDelete} />
              </div>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
