import _ from "lodash";
import { slugify } from "../common/index.js";
import {
  EDIT_CONTACT,
  CREATE_CONTACT,
  CREATE_CONTACTS,
  FETCH_CONTACTS,
  FETCH_CONTACT,
  DELETE_CONTACT
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTACTS:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case FETCH_CONTACT:
      return { ...state };
    case CREATE_CONTACT:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_CONTACTS:
      return { ...state, ...action.payload };
    case EDIT_CONTACT:
      const newState = _.omit(state, action.payload.id);
      const newId = slugify(action.payload.name);
      return {
        ...newState,
        [newId]: { ...action.payload, id: newId }
      };
    case DELETE_CONTACT:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
