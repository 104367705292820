import _ from "lodash";
import { createSelector } from "reselect";

const contactsSelector = (state) => state.contacts;
const filteredContactsSelector = (state) => state.searchResults;

const getContacts = (contacts, searchResults) => {
  const searchedContacts = _.filter(contacts, (contact) => {
    return searchResults.indexOf(contact.id) !== -1;
  });
  return searchedContacts;
};

export default createSelector(
  contactsSelector,
  filteredContactsSelector,
  getContacts
);
