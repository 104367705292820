import React from "react";
import { connect } from "react-redux";
import { createContacts } from "../../actions/index.js";
import ContactFormTable from "./ContactFormTable.js";

class ContactCreateBatch extends React.Component {
  onSubmit = (formValues) => {
    this.props.createContacts(formValues);
  };

  render() {
    return (
      <div>
        <h3>Create Contacts</h3>
        <ContactFormTable onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default connect(
  null,
  { createContacts }
)(ContactCreateBatch);
