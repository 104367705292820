import React from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal.js";
import history from "../../history.js";
import { connect } from "react-redux";
import { fetchContact, deleteContact } from "../../actions/index.js";

class ContactDelete extends React.Component {
  componentDidMount() {
    this.props.fetchContact(this.props.match.params.id);
  }

  renderActions() {
    const { id } = this.props.match.params;
    return (
      <React.Fragment>
        <button
          onClick={() => this.props.deleteContact(id)}
          className="ui negative button"
        >
          Delete
        </button>
        <Link to="/" className="ui button">
          Cancel
        </Link>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.contact) {
      return "Are you sure you want to delete this contact?";
    }
    return `Are you sure you want to delete this contact with Name: "${
      this.props.contact.name
    }"?"`;
  }

  render() {
    return (
      <div>
        <Modal
          title="Delete Contact"
          content={this.renderContent()}
          actions={this.renderActions()}
          onDismiss={() => history.push("/")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { contact: state.contacts[ownProps.match.params.id] };
};

export default connect(
  mapStateToProps,
  { fetchContact, deleteContact }
)(ContactDelete);
