import React from "react";
import { connect } from "react-redux";

import { fetchContacts, searchContacts } from "../../actions/index.js";
import SearchBar from "../SearchBar.js";
import ContactList from "./ContactList.js";
import CreateLink from "./CreateLink.js";
import SelectedContactsSelector from "../../selectors/contactSearchResults";
import SearchHistory from "../SearchHistory.js";

class ContactsSearch extends React.Component {
  componentDidMount() {
    const { term } = this.props.match.params;
    if (term) this.props.searchContacts(term);
  }

  onTermSubmit = async (term) => {
    this.props.searchContacts(term);
  };

  onHistoryTermSubmit = async (term) => {
    if (term) this.props.searchContacts(term);
  };

  render() {
    return (
      <div>
        <SearchBar onFormSubmit={this.onTermSubmit} />
        <h2>Contacts</h2>
        <SearchHistory
          searchTerms={this.props.searchTerms}
          onHistoryTermSubmit={this.onHistoryTermSubmit}
        />
        <div className="ui celled list">
          <ContactList contacts={this.props.contacts} />
        </div>
        <CreateLink />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: SelectedContactsSelector(state),
    searchTerms: state.searchTerms
  };
};

export default connect(
  mapStateToProps,
  { fetchContacts, searchContacts }
)(ContactsSearch);
