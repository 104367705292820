import React from "react";
import { Link } from "react-router-dom";

const renderAdmin = (contact) => {
  return (
    <div className="right floated content">
      <Link to={`/edit/${contact.id}`} className="ui button primary">
        Edit
      </Link>
      <Link to={`/delete/${contact.id}`} className="ui button negative">
        Delete
      </Link>
    </div>
  );
};

const ContactList = ({ contacts }) => {
  return contacts.map((contact) => {
    return (
      <div className="item" key={contact.id}>
        {renderAdmin(contact)}
        <i className="large middle aligned icon book" />
        <div className="content">
          <Link to={`/view/${contact.id}`} className="header">
            {contact.name}
          </Link>
          <div className="phone">{contact.phone}</div>
        </div>
      </div>
    );
  });
};

export default ContactList;
