import "./ContactForm.scss";
import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { validateSet } from "./Validate.js";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

class ContactFormTable extends React.Component {
  renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  };

  renderInput = ({ input, label, meta }) => {
    const className = `field${meta.error && meta.touched ? " error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderContacts = ({ fields, meta: { error, submitFailed } }) => {
    fields.length || fields.push({});
    return (
      <div>
        {submitFailed && error && <span>{error}</span>}
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {fields.map((contact, index) => (
            <div className="two fields" key={index}>
              <Field
                name={`${contact}.name`}
                type="text"
                component={this.renderInput}
                label="Name"
              />
              <Field
                name={`${contact}.phone`}
                type="text"
                component={this.renderInput}
                label="Phone"
              />
              <button
                className="ui negative button"
                type="button"
                title="Remove Contact"
                onClick={() => fields.remove(index)}
              >
                <i className="middle aligned icon delete" />
              </button>
            </div>
          ))}
        </ReactCSSTransitionGroup>
        <button
          className="ui primary button"
          type="button"
          onClick={() => fields.push({})}
          disabled={fields.length >= 50 ? true : false}
        >
          Add Contact
        </button>
      </div>
    );
  };

  render() {
    const { pristine, reset, submitting } = this.props;

    return (
      <form
        onSubmit={this.props.handleSubmit(this.props.onSubmit)}
        className="ui form error"
      >
        <FieldArray name="contacts" component={this.renderContacts} />
        <div>
          <button
            type="submit"
            className="ui primary button"
            disabled={submitting}
          >
            Submit
          </button>
          <button
            type="button"
            className="ui negative button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: "contactFormTable",
  validate: validateSet
})(ContactFormTable);
